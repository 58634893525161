<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" md="4">
        <DatePicker @onDateUpdate="onDateUpdate" />
      </v-col>
      <v-col cols="12" md="4">
        <Export
          v-show="showExport && !expandMode"
          :alerts="containerAlerts"
          :label="$t('export.exportAll')"
          class="ml-2 mt-2"
        />
      </v-col>
    </v-row>

    <v-layout row wrap class="mb-4 ml-2">
      <v-switch
        v-model="viewAll"
        :label="
          viewAll
            ? 'Alertes non-lues sur la plage de dates sélectionnée'
            : 'Toutes les alertes sur la plage de dates sélectionnée'
        "
        color="secondary"
      ></v-switch>
    </v-layout>

    <div v-if="!loading">
      <RuleContainer
        v-for="rule in rules"
        :key="rule.rule_id"
        :id="rule.rule_id"
        :options="options[rule.rule_id]"
        :rule="rule"
        :viewAll="viewAll"
        :dateRange="dateRange"
        @onExpand="expandTable"
        @onToggle="toggleTables"
        @containerLoaded="ruleContainerLoaded"
      />
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";

export default {
  components: {
    DatePicker: () => import("@/components/Alerts/DatePicker"),
    Export: () => import("@/components/Exports/ExportAlerts"),
    RuleContainer: () => import("@/components/Alerts/RuleContainer"),
  },
  data: () => ({
    loading: true,
    viewAll: false,
    dateRange: null,
    showExport: false,
    expandMode: false,
    containerAlerts: {},
    options: {},
  }),
  computed: {
    ...mapState({
      rules: (state) => state.rules.rulesNames,
    }),
  },
  methods: {
    scrollTo: function (id) {
      // Programmatic Scrolling
      const rulesList = Object.keys(this.rules).map((x) => x);

      if (!Array.isArray(id)) {
        id = [id];
      }

      const scrollTo = rulesList.find((ruleId) => {
        return id.includes(ruleId);
      });

      if (scrollTo) {
        setTimeout(() => {
          this.$vuetify.goTo(`#${scrollTo}`);
        }, 100);
      }
    },
    ruleContainerLoaded: function ({ ruleId, alerts }) {
      this.containerAlerts[ruleId] = alerts;

      // TODO - enhancement required
      if (
        Object.keys(this.containerAlerts).length ===
        Object.keys(this.rules).length
      ) {
        this.showExport = true;
      }
    },
    expandTable: function (ruleId) {
      this.options = Object.keys(this.options).reduce((acc, key) => {
        acc[key] = {
          ...this.options[key],
          light: false,
          show: key === ruleId,
          expand: key === ruleId,
        };

        return acc;
      }, {});

      this.expandMode = true;
      this.showExport = false;
    },
    toggleTables: function (ruleId) {
      this.options = Object.keys(this.options).reduce((acc, key) => {
        acc[key] = {
          ...this.options[key],
          light: true,
          show: true,
          expand: false,
        };

        return acc;
      }, {});

      if (ruleId) {
        this.scrollTo(ruleId);
      }

      this.expandMode = false;
      this.showExport = true;
    },
    onDateUpdate(value) {
      this.dateRange = value;
    },
  },
  async created() {
    await this.$store.dispatch("rules/loadRulesNames");

    if (this.$route.params.rulesTargetted && this.$route.params.rulesTargetted.length > 0) {
      this.scrollTo(this.$route.params.rulesTargetted);

      const dates = [
        dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ];

      this.$store.dispatch("alerts/processDateRange", dates);
    }
  },
  watch: {
    rules(rules) {
      this.options = Object.keys(rules).reduce((acc, key) => {
        acc[key] = {
          light: !(this.$route.params.expand === key),
          show: this.$route.params.expand
            ? this.$route.params.expand === key
            : true,
          expand: this.$route.params.expand === key,
        };

        return acc;
      }, {});

      this.loading = false;
    },
  },
};
</script>

<style>
.unseen > td:first-child {
  border-left: 5px solid #f28c00;
}
</style>
